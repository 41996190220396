import { assign } from 'xstate';

export const resetBoard = assign({
  board: () => {
    const board = new Array(6);
    for (let row = 0; row < 6; row +=1) {
      board[row] = [0, 0, 0, 0, 0, 0, 0];
    }
    return board;
  },
  lastPlayed: null
});

export const findDropSpot = assign({
  lastPlayed: ({ board }, { column }) => {
    let lastPlayed = null;
    // Search the rows from the bottom until we find an open spot
    for (let row = (board.length - 1); row >= 0; row -= 1) {
      if (board[row][column] < 1) {
        // A spot is open, plop our game piece here
        lastPlayed = [row, column];
        break;
      }
    }
    return lastPlayed;
  }
});

export const dropPiece = assign({
  board: ({ board, lastPlayed }, { player }) => {
    /* istanbul ignore next */
    if (!lastPlayed) {
      return board;
    }
    const newBoard = [ ...board ];
    const [row, col] = lastPlayed;
    newBoard[row] = newBoard[row].map((val, idx) => idx === col ? (player === 'red' ? 1 : 2) : val);
    return newBoard;
  }
});

export const columnIsNotFull = ({ board }, { column }) => board[0][column] === 0;

export const isGameADraw = ({ board }) => board[0].filter(val => val === 0).length === 0;

export const didLastPlayWin = ({ board, lastPlayed }) => {
  if (!lastPlayed) {
    return false;
  }

  const [lastRow, lastCol] = lastPlayed;
  const lastVal = board[lastRow][lastCol];

  if (lastVal === 0 || typeof lastVal === 'undefined') {
    return false;
  }

  // Check horizontally from last piece
  let inARow = 1;
  for (let i = (lastCol - 1); i >= 0; i -= 1) {
    if (board[lastRow][i] !== lastVal) {
      break;
    }
    inARow += 1;
  }
  for (let i = (lastCol + 1); i < 7; i += 1) {
    if (board[lastRow][i] !== lastVal) {
      break;
    }
    inARow += 1;
  }
  if (inARow >= 4) {
    return true;
  }

  // Check vertically from last piece
  inARow = 1;
  for (let i = (lastRow - 1); i >= 0; i -= 1) {
    if (board[i][lastCol] !== lastVal) {
      break;
    }
    inARow += 1;
  }
  for (let i = (lastRow + 1); i < 6; i += 1) {
    if (board[i][lastCol] !== lastVal) {
      break;
    }
    inARow += 1;
  }
  if (inARow >= 4) {
    return true;
  }

  // Check forward diagnoal from last piece
  inARow = 1;
  for (let i = (lastRow + 1), j = (lastCol - 1); i < 6 && j >= 0; i += 1, j -= 1) {
    if (board[i][j] !== lastVal) {
      break;
    }
    inARow += 1;
  }
  for (let i = (lastRow - 1), j = (lastCol + 1); i >= 0 && j < 7; i -= 1, j += 1) {
    if (board[i][j] !== lastVal) {
      break;
    }
    inARow += 1;
  }
  if (inARow >= 4) {
    return true;
  }

  // Check reverse diagnoal from last piece
  inARow = 1;
  for (let i = (lastRow - 1), j = (lastCol - 1); i >= 0 && j >= 0; i -= 1, j -= 1) {
    if (board[i][j] !== lastVal) {
      break;
    }
    inARow += 1;
  }
  for (let i = (lastRow + 1), j = (lastCol + 1); i < 6 && j < 7; i += 1, j += 1) {
    if (board[i][j] !== lastVal) {
      break;
    }
    inARow += 1;
  }
  if (inARow >= 4) {
    return true;
  }

  return false;
};
