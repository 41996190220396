import { Machine, send } from 'xstate';

import {
  columnIsNotFull,
  didLastPlayWin,
  dropPiece,
  findDropSpot,
  isGameADraw,
  resetBoard
} from './gameLogic';

const gameMachine = Machine({
  id: 'gameMachine',
  initial: 'idle',
  context: {
    board: null,
    lastPlayed: null
  },
  states: {
    idle: {
      on: { START: 'playing' }
    },
    playing: {
      entry: 'resetBoard',
      initial: 'redsTurn',
      states: {
        redsTurn: {
          on: {
            '': [
              { target: 'winnerDelay', cond: 'didLastPlayWin', actions: send('BLACK_WINS', { delay: 500 }) },
              { target: '#gameMachine.draw', cond: 'isGameADraw' }
            ],
            DROP_PIECE: {
              target: 'blacksTurn',
              cond: 'columnIsNotFull',
              actions: ['findDropSpot', 'dropPiece']
            }
          }
        },
        blacksTurn: {
          on: {
            '': [
              { target: 'winnerDelay', cond: 'didLastPlayWin', actions: send('RED_WINS', { delay: 500 }) },
              { target: '#gameMachine.draw', cond: 'isGameADraw' }
            ],
            DROP_PIECE: {
              target: 'redsTurn',
              cond: 'columnIsNotFull',
              actions: ['findDropSpot', 'dropPiece']
            }
          }
        },
        // This exists to let the animation finish before declaring the winner
        winnerDelay: {
          on: {
            RED_WINS: '#gameMachine.winner.red',
            BLACK_WINS: '#gameMachine.winner.black',
          }
        }
      }
    },
    draw: {
      on: { START: 'playing' }
    },
    winner: {
      states: {
        red: {},
        black: {}
      },
      on: { START: 'playing' }
    }
  }
}, {
  actions: {
    findDropSpot,
    dropPiece,
    resetBoard
  },
  guards: {
    columnIsNotFull,
    didLastPlayWin,
    isGameADraw
  }
});

export default gameMachine;
